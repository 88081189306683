import React from 'react';
import { PageProps, graphql, useStaticQuery } from 'gatsby';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';

import Layout, { LayoutOptions, HeroSectionColors } from '../../components/Layout/Layout';
import { LocaleNameSpaceKeys } from '../../localization/translations';
import PageMenu from '../../components/PageMenu/PageMenu';
import aboutUsMenu from '../../data/page-menus/about-us';
import SmallImage from '../../components/Image/SmallImage/SmallImage';
import { useLocaleContext } from '../../context/Locale';
import { LocaleTag } from '../../../config/locales';
import ExternalLink from '../../components/ExternalLink/ExternalLink';

import './style.scss';

type Props = PageProps & WithTranslation;

const AboutUs: React.FC<Props> = (props: Props) => {
  const { t } = props;
  const { activeLocaleTag } = useLocaleContext();

  const layoutOptions: LayoutOptions = {
    seo: {
      title: t('aboutUs.seo.title'),
    },
    page: {
      title: t('aboutUs.heading'),
    },
    heroSection: {
      options: {
        accentColor: HeroSectionColors.CHERRY_RED,
        heroImage: {
          fileName: 'hero-image-1.jpg',
          alt: 'Image',
        },
      },
    },
  };

  // const getContentBlocks = () => {
  //   const contentBlocks: Array<any> = t('aboutUs.contentBlock.blocks', { returnObjects: true });
  //   return contentBlocks.map((block: any, i) => (
  //     <React.Fragment key={`${block.heading}-${i}`}>
  //       <h5>{block.heading}</h5>
  //       <p>{block.body}</p>
  //     </React.Fragment>
  //   ));
  // };

  const GPWfileName =
    activeLocaleTag === LocaleTag.EN
      ? 'great-place-to-work-nov-2023-about-us.jpg'
      : 'great-place-to-work-nov-2023-about-us-fr.jpg';

  const {
    allFile: { edges },
  } = useStaticQuery(graphql`
    query aboutUsQuery {
      allFile(filter: { relativePath: { regex: "/about-us//" }, ext: { eq: ".jpg" } }) {
        edges {
          node {
            publicURL
            name
          }
        }
      }
    }
  `);

  const institutions = edges.reduce(
    (accum: any, curr: any) => {
      const paths = curr.node.name.split('-');
      const next = { ...accum };

      if (next[paths[0]]) {
        next[paths[0]][paths[1]] = curr.node.publicURL;
      } else {
        next[paths[0]] = {
          [paths[1]]: curr.node.publicURL,
        };
      }

      return next;
    },
    {
      CDIC: {
        'link-en': 'https://www.cdic.ca/your-coverage/list-of-member-institutions/',
        'link-fr': 'https://www.sadc.ca/votre-protection/membres-de-la-sadc/',
        name: t(`${LocaleNameSpaceKeys.COMMON}:institutions.cdic.name`),
      },
      CBA: {
        'link-en': 'https://www.cba.ca/member-banks',
        'link-fr': 'https://www.cba.ca/member-banks',
        name: t(`${LocaleNameSpaceKeys.COMMON}:institutions.cba.name`),
      },
      payments_canada_2: {
        'link-en': 'https://www.payments.ca/our-directories/member-financial-institutions',
        'link-fr':
          'https://www.paiements.ca/r%C3%A9pertoires/institutions-financi%C3%A8res-membres',
        name: t(`${LocaleNameSpaceKeys.COMMON}:institutions.paymentsCanada.name`),
      },
      RDBA: {
        'link-en': 'https://www.rdba.ca/',
        'link-fr': 'https://www.rdba.ca/fr/',
        name: t(`${LocaleNameSpaceKeys.COMMON}:institutions.rdba.name`),
      },
    }
  );

  return (
    <Layout options={layoutOptions} className="About">
      <div className="grid-container">
        <div className="row row-wrap">
          <section className="column column-60">
            <h2 className="h3">{t('aboutUs.companyOverview.heading')}</h2>
            <p>
              <Trans i18nKey="aboutUs.companyOverview.body" t={t} />
            </p>
            {/* <div className="About__second-block">
              <h3>{t('aboutUs.contentBlock.heading')}</h3>
              {getContentBlocks()}
            </div> */}
            <div className="row row-wrap institutions">
              {Object.keys(institutions).map((institution) => (
                <div className="column column-25" key={institution}>
                  <ExternalLink
                    href={institutions[institution][`link-${activeLocaleTag}`]}
                    aria-label={institutions[institution].name}
                  >
                    <img
                      src={institutions[institution][activeLocaleTag]}
                      alt={institutions[institution].name}
                    />
                  </ExternalLink>
                </div>
              ))}
            </div>
          </section>

          <aside className="column column-33">
            <PageMenu menu={aboutUsMenu} />
            <div>
              <SmallImage
                fileName={GPWfileName}
                alt={t(`${LocaleNameSpaceKeys.COMMON}:ariaLabels.gptwImage`)}
              />
            </div>
          </aside>
        </div>
      </div>
    </Layout>
  );
};

export default withTranslation(LocaleNameSpaceKeys.ABOUT_US)(AboutUs);
