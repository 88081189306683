import RouteKeys from '../../../config/RouteKeys';
import { PageMenuLinks } from './pageMenuTypes';
import { getPathFromRouteKey } from '../../shared/utils/getPathFromRouteKey';

const aboutUsMenu: PageMenuLinks = [
  {
    to: getPathFromRouteKey(RouteKeys.ABOUT_US.COMMUNITY_CAREGIVING),
    textKey: 'pageTitles.communityCaregiving',
  },
  {
    to: getPathFromRouteKey(RouteKeys.ABOUT_US.FRAUD_ALERT),
    textKey: 'pageTitles.fraudAlert',
  },
  {
    to: getPathFromRouteKey(RouteKeys.ABOUT_US.SCC),
    textKey: 'pageTitles.scc',
  },
];

export default aboutUsMenu;
