import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

// Note: You can change "images" to whatever you'd like.
interface OwnProps {
  fileName: string;
  alt: string;
  className?: string;
}

type Props = OwnProps;

// Ideal for images up to 300px
const SmallImage: React.FC<Props> = (props) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fixed(width: 300, quality: 100) {
                  ...GatsbyImageSharpFixed_withWebp
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const image = data.images.edges.find((n: any) => {
        return n.node.relativePath.includes(props.fileName);
      });
      if (!image) {
        if (process.env.NODE_ENV === 'development') {
          console.error(
            `Cannot find the image with name '${props.fileName}' in the '/src/assets/images' folder. Did you ensure it exists and the gatsby-config still points to the '/src/assets/images' path? `
          );
        }

        return null;
      }

      return (
        <Img
          className={`SmallImage ${props.className || ''}`}
          alt={props.alt}
          fixed={image.node.childImageSharp.fixed}
          imgStyle={{ width: '100%', height: '100%', maxWidth: '300px' }}
        />
      );
    }}
  />
);

export default SmallImage;
